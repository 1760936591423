/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { MuiButton, MuiDatePicker } from '@onehope/design-system-ohw';
import { styles } from '@onehope/design-system';
import { FormikProps, FastField } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import {
  FormWrapper,
  DetailsContainer,
  DetailsHeader,
  DetailsTitle,
  StraightLine,
  EditIcon,
} from '../index.styles';
import { MyFormValues } from './FormTypes';
import {
  formatPhone,
  Address,
} from '../../../Events/EventDetailsForm/EventDetailsForm';
import SummaryLine from '../SummaryLine';
import ContactPreferenceEditMutation from '../../../../mutations/ContactPreference/ContactPreferenceEditMutation';
import AddressAutoCompleteAddressMutation from '../../../../mutations/Event/AddressFormAddressAutoCompleteMutation';
import AddressFormGooglePlacesZipMutation from '../../../../mutations/Event/AddressFormGooglePlacesZipMutation';
import {
  Container,
  Spacer,
} from '../../../Dialog/EventCreateDialog/FooterButtons';
import {
  RadioButtonRow,
  RadioButtonRowGroup,
} from '../../../../common/RadioButton';
import { PhoneMask } from '../../../Contacts/AddContactDialog/PhoneMask';
import { dateInputFormat } from '../../../Contacts/AddContactDialog/helpers';
import AutoComplete from '../../../Events/EventDetailsForm/AutoComplete';
import { allowedStates } from '../../../../../../../react-components/checkout/src/Shipment/utils/statesWeCanShipTo';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import {
  MiniSelect,
  SelectInput,
  Label,
  DefaultOption,
  OptionLabel,
  ArrowDropDown,
  MenuProps,
} from '../../../Events/EventDetailsForm/index.styles';
import {
  Row,
  Col,
  Input,
} from '../../../Contacts/AddContactDialog/index.styles';
import { statesAbbrev } from '../../../../utils/statesWeCanShipTo';

const {
  cssConstants: { textColor, disabledColor, font },
} = styles;

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
  contact: ContactType;
};

const ButtonContainer = styled(Container)`
  margin-top: 12px;
  margin-bottom: 36px;
`;

const DatePicker = styled(MuiDatePicker)`
  && {
    margin-bottom: 25px;
  }
`;

export const SubTitle = styled.div<{ editing: number }>`
  /* width: 96px; */
  height: 22px;
  /* font-family: ProximaNova; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ editing }) => (editing ? disabledColor : textColor)};
  margin-top: 17px;
  margin-bottom: 8px;
`;

export const StyledTypography = styled(Typography)<{
  disabled: boolean;
  fontSize?: number;
  lineHeight?: number;
}>`
  && {
    &.MuiTypography-body1 {
      font-family: ${font};
      font-size: ${({ fontSize }) => fontSize || 16}px;
      /* font-family: ProximaNova; */
      line-height: ${({ lineHeight }) => `${lineHeight}px` || 1.38};
      color: ${({ disabled }) => (disabled ? disabledColor : textColor)};
    }
  }
`;

export const CheckBoxIcon = styled(Checkbox)<{ editing: number }>`
  && {
    :hover,
    &.Mui-checked:hover {
      background-color: rgba(26, 26, 26, 0.1);
    }
    &.MuiCheckbox-colorPrimary,
    &.MuiCheckbox-colorSecondary {
      color: ${({ editing }) => (editing ? disabledColor : textColor)};
    }
  }
`;

const EditingWrapper = styled.div<{ editing: boolean }>`
  color: ${({ editing }) => (editing ? disabledColor : textColor)};
`;

export const ContactDetailsForm = (
  props: FormProps & FormikProps<MyFormValues>,
) => {
  const {
    values,
    errors,
    touched,
    contact,
    handleReset,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    mutationLoading,
    setFieldTouched,
  } = props;
  const {
    firstName,
    lastName,
    organizationName,
    email,
    contactType,
    createDate,
    mobilePhone,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    dateOfBirth,
    preferredCustomerJoinDate,
    lifeTimeSpend,
    hostWineTasting,
    joinWineClub,
    becomePC,
    joinAsCE,
    corporateGifting,
  } = values;

  /* MUTATIONS */
  const [
    addressAutoComplete,
    { loading: mutationAddressAutoCompleteLoading },
  ] = useMutation(AddressAutoCompleteAddressMutation);

  const [
    googlePlacesZip,
    { loading: mutationGooglePlacesZipLoading },
  ] = useMutation(AddressFormGooglePlacesZipMutation);

  const [contactPreferenceEdit, { loading }] = useMutation(
    ContactPreferenceEditMutation,
  );

  const [editing, setEditing] = useState(false);
  const [isAddressMenuOpen, setIsAddressMenuOpen] = useState(false);
  const [addressPredictions, setAddressPredictions] = useState({});

  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleAddressSelected = async (selectedItem: any) => {
    const { addressLineOne, city, state, zip, placeId } = selectedItem;
    handleSetValue('addressLineOne', addressLineOne);
    handleSetValue('city', city);
    handleSetValue('state', state);
    closeMenu('addressLineOne');
    if (!zip) {
      const variables = {
        input: {
          placeId: placeId,
        },
      };
      return googlePlacesZip({ variables }).then(
        ({
          data: {
            googlePlacesZip: { zip },
          },
        }) => {
          handleSetValue('zip', zip || '');
        },
      );
    }
  };

  const handleOuterClick = (field: string) => {
    closeMenu(field);
  };

  const handleAddressChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('addressLineOne', event);
    const searchTerm = event.target.value;
    const variables = {
      input: {
        query: searchTerm,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setIsAddressMenuOpen(true);
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions.filter((address: Address) => {
          return (
            allowedStates.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: Address) => {
        return !!address;
      }) as Address[];
      setAddressPredictions(truthyList);
    });
  };

  const closeMenu = (field: string) => {
    switch (field) {
      case 'addressLineOne': {
        setIsAddressMenuOpen(false);
        break;
      }
    }
  };

  const toggle = async (name: any, e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const interestedInData = {
      becomePC,
      joinAsCE,
      joinWineClub,
      hostWineTasting,
      corporateGifting,
    };
    await contactPreferenceEdit({
      variables: {
        input: {
          contactPreferenceId: contact?.contactPreferences?.contactPreferenceId,
          interestedIn: {
            ...interestedInData,
            [name]: e.target.checked,
          },
        },
      },
    })
      .then((data: any) => {
        setFieldValue(name, e.target.checked);
        setFieldTouched(name, true, false);
      })
      .catch(async (errors: any) => {
        console.log(errors);
      });
  };

  const handleSetValue = (field: string, selectedItem: string) => {
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const handleSelectChange = (field: string, selectedItem: string) => {
    closeMenu(field);
    handleSetValue(field, selectedItem);
  };

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const onCancel = () => {
    handleReset();
    toggleEdit();
  };

  const detailsTitle = editing ? 'Edit personal details' : 'Personal Details';
  const isPerson = contactType === 'PERSON';
  const shippingAddress =
    addressLineOne || addressLineTwo || city || state || zip;
  return (
    <FormWrapper onSubmit={handleSubmit}>
      <DetailsContainer>
        <DetailsHeader>
          <DetailsTitle>{detailsTitle}</DetailsTitle>
          {!editing && <EditIcon fontSize="small" onClick={toggleEdit} />}
        </DetailsHeader>
        <StraightLine />
        {editing ? (
          <>
            <RadioButtonRowGroup
              id="contactType"
              label=""
              value={'PERSON'}
              error={errors.contactType}
              touched={touched.contactType}
            >
              <FastField
                component={RadioButtonRow}
                name="contactType"
                id="PERSON"
                label="A person"
              />
              <FastField
                component={RadioButtonRow}
                name="contactType"
                id="ORGANIZATION"
                label="An organization"
              />
            </RadioButtonRowGroup>
            {!isPerson && (
              <Input
                label="Name of Organization"
                placeholder="Mack's Magical Muffins"
                id="organizationName"
                name="organizationName"
                value={organizationName}
                fullWidth
                hasError={
                  !isSubmitting &&
                  touched.organizationName &&
                  Boolean(errors.organizationName)
                }
                hasSuccess={
                  !isSubmitting &&
                  ((isEmpty(touched) && isEmpty(errors)) ||
                    ((touched.organizationName ||
                      !Boolean(touched.organizationName)) &&
                      !Boolean(errors.organizationName)))
                }
                error={
                  touched.organizationName && Boolean(errors.organizationName)
                }
                helperText={
                  errors.organizationName &&
                  touched.organizationName &&
                  errors.organizationName
                }
                onChange={(e: React.SyntheticEvent) =>
                  change('organizationName', e)
                }
              />
            )}
            <Input
              label={isPerson ? 'First name' : "Contact's first name"}
              placeholder="Jane"
              id="firstName"
              name="firstName"
              value={firstName}
              fullWidth
              hasError={
                !isSubmitting && touched.firstName && Boolean(errors.firstName)
              }
              hasSuccess={
                !isSubmitting &&
                ((isEmpty(touched) && isEmpty(errors)) ||
                  ((touched.firstName || !Boolean(touched.firstName)) &&
                    !Boolean(errors.firstName)))
              }
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              onChange={(e: React.SyntheticEvent) => change('firstName', e)}
            />
            <Input
              label={isPerson ? 'Last name' : "Contact's last name"}
              placeholder="Austen"
              id="lastName"
              name="lastName"
              value={lastName}
              fullWidth
              hasError={
                !isSubmitting && touched.lastName && Boolean(errors.lastName)
              }
              hasSuccess={
                !isSubmitting &&
                ((isEmpty(touched) && isEmpty(errors)) ||
                  ((touched.lastName || !Boolean(touched.lastName)) &&
                    !Boolean(errors.lastName)))
              }
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              onChange={(e: React.SyntheticEvent) => change('lastName', e)}
            />
            <Input
              label="Email address"
              placeholder="jane@gmail.com"
              id="email"
              name="email"
              value={email}
              fullWidth
              disabled
              hasError={!isSubmitting && touched.email && Boolean(errors.email)}
              hasSuccess={
                !isSubmitting && touched.email && !Boolean(errors.email)
              }
              error={touched.email && Boolean(errors.email)}
              helperText={errors.email && touched.email && errors.email}
              onChange={(e: React.SyntheticEvent) => change('email', e)}
            />
            <Input
              label="Mobile phone # (optional)"
              placeholder="(555) 123 - 4567"
              id="mobilePhone"
              name="mobilePhone"
              value={mobilePhone}
              fullWidth
              hasError={
                !isSubmitting &&
                touched.mobilePhone &&
                Boolean(errors.mobilePhone)
              }
              hasSuccess={
                !isSubmitting &&
                ((isEmpty(touched) && isEmpty(errors)) ||
                  ((touched.mobilePhone || !Boolean(touched.mobilePhone)) &&
                    !Boolean(errors.mobilePhone)))
              }
              mask={PhoneMask}
              error={touched.mobilePhone && Boolean(errors.mobilePhone)}
              helperText={
                errors.mobilePhone && touched.mobilePhone && errors.mobilePhone
              }
              onChange={(e: React.SyntheticEvent) => change('mobilePhone', e)}
            />
            <AutoComplete
              id="shippingAddressDropDown"
              inputId="addressLineOne"
              label="Shipping address (optional)"
              placeholder="123 Madison ave"
              value={addressLineOne}
              error={errors.addressLineOne}
              touched={touched.addressLineOne}
              isSubmitting={isSubmitting}
              isWithinDialog={false}
              isMenuOpen={isAddressMenuOpen}
              handleAddressSelected={handleAddressSelected}
              handleInputChange={handleAddressChange}
              handleOuterClick={handleOuterClick}
              objectPredictions={addressPredictions}
            />
            <Input
              label="Address 2"
              placeholder="Building, Suite, or Apt #"
              id="addressLineTwo"
              name="addressLineTwo"
              value={addressLineTwo}
              fullWidth
              validate
              hasError={
                !isSubmitting &&
                touched.addressLineTwo &&
                Boolean(errors.addressLineTwo)
              }
              hasSuccess={
                !isSubmitting &&
                touched.addressLineTwo &&
                !Boolean(errors.addressLineTwo)
              }
              error={touched.addressLineTwo && Boolean(errors.addressLineTwo)}
              helperText={
                errors.addressLineTwo &&
                touched.addressLineTwo &&
                errors.addressLineTwo
              }
              onChange={(e: React.SyntheticEvent) =>
                change('addressLineTwo', e)
              }
            />
            <Row>
              <Col>
                <Input
                  label="City"
                  placeholder="Los Angeles"
                  id="city"
                  name="city"
                  value={city}
                  width="264"
                  fullWidth
                  validate
                  hasError={
                    !isSubmitting && touched.city && Boolean(errors.city)
                  }
                  hasSuccess={
                    !isSubmitting && touched.city && !Boolean(errors.city)
                  }
                  error={touched.city && Boolean(errors.city)}
                  helperText={errors.city && touched.city && errors.city}
                  onChange={(e: React.SyntheticEvent) => change('city', e)}
                />
              </Col>
              <Col>
                <Label htmlFor="State">State</Label>
                <MiniSelect
                  IconComponent={ArrowDropDown}
                  input={
                    <SelectInput
                      id="state"
                      width="144"
                      hasError={touched.state && Boolean(errors.state)}
                    />
                  }
                  value={state}
                  displayEmpty
                  error={touched.state && Boolean(errors.state)}
                  helperText={errors.state && touched.state && errors.state}
                  onChange={(e: React.SyntheticEvent) => change('state', e)}
                  error={touched.state && Boolean(errors.state)}
                  inputProps={{
                    name: 'state',
                    id: 'state',
                    MenuProps: MenuProps,
                  }}
                >
                  >
                  <MenuItem component="li" value="">
                    <DefaultOption>Select one</DefaultOption>
                  </MenuItem>
                  {statesAbbrev.map(state => (
                    <MenuItem
                      button={true}
                      component="li"
                      key={state}
                      value={state}
                    >
                      <OptionLabel>{state}</OptionLabel>
                    </MenuItem>
                  ))}
                </MiniSelect>
              </Col>
            </Row>
            <Input
              label="Zipcode"
              placeholder="90210"
              id="zip"
              name="zip"
              value={zip}
              width="146"
              fullWidth
              validate
              hasError={!isSubmitting && touched.zip && Boolean(errors.zip)}
              hasSuccess={!isSubmitting && touched.zip && !Boolean(errors.zip)}
              error={touched.zip && Boolean(errors.zip)}
              helperText={errors.zip && touched.zip && errors.zip}
              onChange={(e: React.SyntheticEvent) => change('zip', e)}
            />
            <Input
              label="Birthday (optional)"
              placeholder="MM / DD / YYYY"
              defaultChecked={false}
              id="dateOfBirth"
              name="dateOfBirth"
              fullWidth
              onChange={e => {
                handleSelectChange(
                  'dateOfBirth',
                  dateInputFormat(e.target?.value),
                );
              }}
              value={dateOfBirth}
              helperText={
                errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
              }
              hasSuccess={
                !isSubmitting &&
                touched.dateOfBirth &&
                !Boolean(errors.dateOfBirth)
              }
              hasError={
                !isSubmitting &&
                touched.dateOfBirth &&
                Boolean(errors.dateOfBirth)
              }
            />
            <ButtonContainer>
              <MuiButton type="SECONDARY" fullWidth onClick={onCancel}>
                CANCEL
              </MuiButton>
              <Spacer />
              <MuiButton
                fullWidth
                type="PRIMARY"
                disabled={false}
                onClick={mutationLoading ? () => {} : handleSubmit}
              >
                SAVE
              </MuiButton>
            </ButtonContainer>
          </>
        ) : (
          <>
            {!isPerson && (
              <SummaryLine title="Organization name" value={organizationName} />
            )}
            <SummaryLine title="Name" value={`${firstName} ${lastName}`} />
            <SummaryLine title="Email" value={email} />
            <SummaryLine title="Phone" value={formatPhone(mobilePhone)} />
            <SummaryLine title="Birthday" value={dateOfBirth} />
            <SummaryLine
              title="Default shipping address"
              address={
                shippingAddress
                  ? ({
                      addressLineOne,
                      addressLineTwo,
                      city,
                      state,
                      zip,
                    } as Address)
                  : undefined
              }
            />
          </>
        )}
        <StraightLine />
        <EditingWrapper editing={editing ? 1 : 0}>
          <SummaryLine title="Date added" value={createDate} />
          <SummaryLine
            title="Lifetime spend"
            value={`$${lifeTimeSpend.toFixed(2)}`}
          />
          <SummaryLine
            title="Reward points balance"
            value={`${contact?.pointBalance} ($${(
              contact?.pointBalance / 100 || 0
            ).toFixed(2)})`}
          />
          <StraightLine />
          <SubTitle editing={editing ? 1 : 0}>Interested in:</SubTitle>
          <FormGroup>
            <FormControlLabel
              label={
                <StyledTypography disabled={editing}>
                  Hosting a wine tasting
                </StyledTypography>
              }
              disabled={editing}
              control={
                <CheckBoxIcon
                  editing={editing ? 1 : 0}
                  checked={hostWineTasting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    toggle('hostWineTasting', e)
                  }
                  value="hostWineTasting"
                />
              }
            />
            <FormControlLabel
              label={
                <StyledTypography disabled={editing}>
                  Joining Wine Club
                </StyledTypography>
              }
              disabled={editing}
              control={
                <CheckBoxIcon
                  editing={editing ? 1 : 0}
                  checked={joinWineClub}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    toggle('joinWineClub', e)
                  }
                  value="joinWineClub"
                />
              }
            />
            <FormControlLabel
              label={
                <StyledTypography disabled={editing}>
                  Joining as a Cause Entrepreneur
                </StyledTypography>
              }
              disabled={editing}
              control={
                <CheckBoxIcon
                  editing={editing ? 1 : 0}
                  checked={joinAsCE}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    toggle('joinAsCE', e)
                  }
                  value="joinAsCE"
                />
              }
            />
            <FormControlLabel
              label={
                <StyledTypography disabled={editing}>
                  Corporate Gifting
                </StyledTypography>
              }
              disabled={editing}
              control={
                <CheckBoxIcon
                  editing={editing ? 1 : 0}
                  checked={corporateGifting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    toggle('corporateGifting', e)
                  }
                  value="corporateGifting"
                />
              }
            />
          </FormGroup>
        </EditingWrapper>
      </DetailsContainer>
    </FormWrapper>
  );
};
